<!--
 * @Descripttion : 订单详情预览-只用作预览  无操作按钮
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-28 10:36:04
 * @LastEditors  : karl
 * @LastEditTime : 2022-01-06 10:20:38
-->
<template>
    <div class="ml_order_detail">
        <a-tabs :default-active-key="(orderObj.pickUpCarStatus>0?'0':'1')">
            <template slot="tabBarExtraContent">
                <span @click="telPhone">
                    400-022-6698
                </span>
            </template>

            <a-tab-pane key="0" tab="办证提车" v-if="orderObj.pickUpCarStatus>0">
                <pickUpCarInfoDetail :isViewPickUp="isViewPickUp" :orderObj="orderObj"></pickUpCarInfoDetail>
            </a-tab-pane>

            <a-tab-pane key="1" tab="办证详情">
                <!-- 基本信息 -->
                <orderBase :orderObj="orderObj"></orderBase>

                <orderFileList :orderObj="orderObj"></orderFileList>
            </a-tab-pane>
            <a-tab-pane key="2" tab="办证轨迹">
                <orderTrail :orderObj="orderObj"></orderTrail>
            </a-tab-pane>
            <a-tab-pane key="3" tab="证件核对" v-if="orderObj.invoiceCheckStatus != null && (orderObj.invoiceCheckStatus == 3 || orderObj.invoiceCheckStatus == 2)">
                <!-- <orderInvoice :orderObj="orderObj" @onSave="onUploadSave"></orderInvoice> -->
                <orderCertificate :orderObj="orderObj"></orderCertificate>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import orderFileList from "../component-file/component-file-list";
import orderBase from "./component-order-detaile-base"; //  订单状态
import orderTrail from "./component-order-detaile-trail"; //  订单轨迹
// import orderInvoice from "./component-order-detaile-invoice"; //  发票证件
import orderCertificate from "./component-order-detaile-certificate-verification"; //证件核对
import pickUpCarInfoDetail from "@/components/pickUpCar/pickUpCarInfoDetail"; //办证提车详情

export default {
    name: "orderdetail",
    components: {
        orderFileList,
        orderBase,
        orderTrail,
        orderCertificate,
        pickUpCarInfoDetail
    },
    props: {
        orderObj: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            isViewPickUp: 1
        };
    },
    mounted() {
        
    },
    methods: {
        telPhone() {
            location.href = "tel:400-022-6698";
        },
        onUploadSave() {
            this.$emit("onSave");
        },
    }
};
</script>
<style lang="scss">
.ml_order_detail {
    .ml_order_table {
        background-color: #fafafa;
        padding: 10px 10px 2px 10px;
    }

    .ml_order_des {
        background-color: #fafafa;
        padding: 10px;
        border-radius: 2px;
    }

    .ml_order_title {
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
        font-size: 16px;
        line-height: 1.5;
        padding: 20px 0 10px 0;
    }
    .ant-descriptions {
        .ant-descriptions-title {
            margin: 0;
            padding: 20px 0 10px 0;
        }

        .ant-descriptions-item-label {
            width: 100px;
        }
    }

    .ml_order_card {
        display: inline-block;
        width: 250px;
        margin: 0 10px 10px 0;
        vertical-align: top;

        .ml_order_img {
            width: 100%;
            height: 180px;
            object-fit: cover;
            text-align: center;
            line-height: 180px;
            overflow: hidden;
            .ml_order_icon {
                width: 50px;
                margin: 0 auto;
                background-color: #fafafa;
            }
        }

        .ant-card-body {
            padding: 10px;
        }
    }
}
</style>
